export default {
  heading: {
    label: "Title",
    checkbox: null,
    field: {
      Eng: "Lorem Ipsum",
      Span: "Función Clave",
      ChS: "关键特点",
    },
    charLimit: 70,
  },
  desc: {
    label: "Description",
    checkbox: true,
    field: {
      Eng: "Etiam sit amet nisl purus in mollis nunc sed id semper.",
      Span: "Aquí hay un texto que es una descripción de la función.",
      ChS: "这里有一些文字是特征描述。",
    },
    charLimit: 200,
  },

  currentLang: "Eng",
};
