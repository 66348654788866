import { useContext, useEffect } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";

const S_HeroVimeo = () => {
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    let heroVars = {
      ...appState,
      currentPath: "/ui/hero-vimeo",
      outputName: "HeroVimeo",
      headerName: "Hero Section w/Vimeo Video Player",
      tags: ["Landing Page Approved"],
      description:
        "The hero section has two main areas for dynamic content, the background image/video (depending on where it's used) and foreground copy. The copy appears over the image in a 'clip' when in tablet or desktop view while the text will appear below the image in mobile view. There are several versions; please check related sections for possible options. *In development.",
      related: [
        ["Hero", "hero"],
        ["Hero: Homepage", "hero-homepage"],
        ["Hero: Local Video", "hero-local-video"],
        ["Hero: SVP", "hero-svp"],
      ],
      htmlSaved: true,
      currentTheme: null,
      scripts: [],
      specs: [
        [
          "Background Video",
          [
            "*Both sizes required",
            "Small: 512 x 512 / 1x1 ratio",
            "Large: 1280 x 720 / 16x9 ratio",
            "Must be hosted on Vimeo", "Video clip loop Length: 10 sec. or less",
          ],
        ],
        ["Video Fallback Image", [ "*2 sizes required", "Small: 512 x 512", "Large: 2048 x 768", "Recommended file size: 50kb to 100kb"]],
        ["Video Fallback Image Type", ["JPG"]],
        ["Headline", ["Character Limit: ~35"]],
        ["Link", ["Character Limit: 85"]],
        ["Copy", ["Only available on home page"]],
      ],
      cis: {
        cmsDoc:
          "https://kingstontechnology.sharepoint.com/:w:/s/creative_fv/ERrpNEEjPsJDnsNmWhvM24EBtSgLQkXPkLXW98SL_rt4fw?e=me9xR8",
        comments: [
          ["Hero Fallback Image", ["Small: 512 x 512", "Large: 2048 x 768"]],
          [
            "Hero Background Video",
            ["Small: 512 x 512 / 1x1 ratio", "Large: 1280 x 720 / 16x9 ratio"],
          ],
        ],
      },
    };
    setAppState(heroVars);
    setContentOptions({
      Headline: {
        label: "Headline",
        checkbox: null,
        field: {
          Eng: "Lorem ipsum",
          Span: "Titular",
          ChS: "标题",
          Gr: "Überschrift",
          Ru: "Заголовок",
          Fr: "Gros titre",
          It: "Titolo",
          Pl: "Nagłówek",
          Port: "Título",
          Viet: "Tiêu đề",
          Turk: "Başlık",
          Ukrn: "Заголовок",
          Thai: "พาดหัว",
          Kor: "표제",
          ChT: "標題",
          Jp: "見出し",
        },
      },
      HeadlineMode: {
        label: "Headline Mode",
        checkbox: null,
        field: ["DIV", "H1"],
        selected: "DIV",
      },
      CTA: {
        label: "CTA",
        checkbox: true,
        field: {
          Eng: "Sed ut perspiciatis",
          Span: "Ver más",
          ChS: "查看更多",
          Gr: "Mehr sehen",
          Ru: "Узнать больше",
          Fr: "Voir plus",
          It: "Vedi altro",
          Pl: "Zobacz więcej",
          Port: "Ver mais",
          Viet: "Xem thêm",
          Turk: "Daha fazla gör",
          Ukrn: "Побачити більше",
          Thai: "ดูเพิ่มเติม",
          Kor: "더보기",
          ChT: "查看更多",
          Jp: "続きを見る",
        },
      },
      SVG: { label: "CTA Icon SVG", checkbox: true, field: "#play-basic" },
      Aligned: {
        label: "Clip Alignment",
        checkbox: null,
        field: ["Left (default)", "Lower Left", "Right", "Lower Right"],
        selected: "Left (default)",
      },
      Alternate: { label: "Enable Alternate (Light Mode)", checkbox: false, field: null },
      currentLang: "Eng",
    });
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "",
      width: window.innerWidth - 24,
    });
  }, []);

  if (!contentOptions) return "...Loading Hero";
  return (
    <section
      className={`s-hero${
        contentOptions.Aligned && contentOptions.Aligned.selected === "Right"
          ? " s-hero--clipRight"
          : contentOptions.Aligned && contentOptions.Aligned.selected === "Lower Left"
            ? " s-hero--clipLowerLeft"
            : contentOptions.Aligned && contentOptions.Aligned.selected === "Lower Right"
              ? " s-hero--clipLowerRight"
              : ""
      }${contentOptions.DarkLight && contentOptions.DarkLight.checkbox ? " s-hero--light" : ""}`}
    >
      <div
        className="s-hero__background" style={{"--fallbackSm": `url("https://media.kingston.com/kingston/landing/ironkey/ironkey_forge_Homepage-sm.jpg")`, "--fallbackLg": `url("/images/fpo/section-carousel/kiwi-sample-backup.jpg")`}}
      >
        <div
          className="e-vimeoPlayer"
          data-vimeo-small="https://vimeo.com/335022330"
          data-vimeo-large="https://vimeo.com/335022339"
        >
         
        </div>
        <div id="vimeo0" className="e-vimeoPlayer__player" data-vimeo-initialized="true">
            <div style={{ padding: "45.31% 0 0 0", position: "relative" }}>
              {/* <iframe
                src="https://player.vimeo.com/video/335022339?h=763686ab27&amp;muted=1&amp;autoplay=1&amp;autopause=0&amp;controls=0&amp;loop=1&amp;background=1&amp;app_id=122963"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen={false}
                style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                title="ktc-hero-video-kingston-is-everywhere-30s-1920"
                data-ready="true"
              ></iframe> */}
              <iframe
                src="https://player.vimeo.com/video/871931446?muted=1&autoplay=1&autopause=0&controls=0&loop=1&background=1&app_id=122963"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen={false}
                style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                title="ktc-hero-video-kingston-is-everywhere-30s-1920"
                data-ready="true"
              ></iframe>
            </div>
          </div>
      </div>
      <div className="s-hero__content">
        <div className="c-headerClip">
          {contentOptions.HeadlineMode && contentOptions.HeadlineMode.selected === "DIV" ? (
            <div className="u-h1 c-headerClip__title">
              {(contentOptions.Headline &&
                contentOptions.Headline.field[contentOptions.currentLang]) ||
                `Heading Text`}
            </div>
          ) : (
            <h1 className="c-headerClip__title">
              {(contentOptions.Headline &&
                contentOptions.Headline.field[contentOptions.currentLang]) ||
                `Heading Text`}
            </h1>
          )}
          <div className="c-headerClip__cta">
            {contentOptions.CTA && contentOptions.CTA.checkbox && (
              <a className="c-headerClip__cta__link" href="#heroLink">
                {contentOptions.SVG.checkbox && contentOptions.SVG.field ? (
                  <svg>
                    <use xlinkHref={`/images/icons-map.svg${contentOptions.SVG.field}`} />
                  </svg>
                ) : (
                  <svg>
                    <use xlinkHref="/images/icons-map.svg#play-basic" />
                  </svg>
                )}
                {contentOptions.CTA.field[contentOptions.currentLang] || `Hero Link`}
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
export default {
  path: "hero-vimeo",
  component: S_HeroVimeo,
  navtxt: "Hero - Vimeo",
  htmlName: "HeroVimeo",
  categoryType: ["landing page", "web"],
};
