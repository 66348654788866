/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import DimensionsContext from "../../../../DimensionsContext";
import OptionsContext from "../../../../OptionsContext";
import datasheetOpts from "./datasheetOpts";

const S_datsheet2025 = () => {
  // These are needed for all sections
  const { appState, setAppState } = useContext(AppContext);
  const { contentOptions, setContentOptions } = useContext(OptionsContext);
  const { dimensions, setDimensions } = useContext(DimensionsContext);

  useEffect(() => {
    // currentPath is so user still gets correct/no iframe wrapper if they use browser navigation.
    // currentTheme: if a value is set it the theme switcher will show, if null the dropdown is hidden. To set only one with no dropdown, put the theme class in section classNames, i.e. t-white
    // viewHeight is used to autoadjust the height in DimensionsContext
    // viewWidth is set and changed outside the web components, but preserved by DimensionsContext
    // outputName is used by IframeWrapper to preload html if it's done. Should start with an "s".
    // htmlSaved is used to determine if there is html available to load; can't read local file directories from browser
    //HideView will disable the toggle and only show either "Edit Mode" or "Default". "edit" will hide edit mode, and "default" will hide default html view.
    // Specs are what's loaded in the specs tab
    // contentOptions are the fields that are editable in that tab. Put an empty {} for no options.
    let datasheetVars = {
      ...appState,
      currentPath: "/ui/datasheet-2025",
      outputName: "Datsheet2025",
      headerName: "Datsheet2025",
      tags: [],
      currentTheme: null,
      description: "Style demo for CMS Datasheet exporter.",
      related: null,
      htmlSaved: false,
      hideView: null,
      scripts: [],
      specs: [],
      cis: {}
    };
    setAppState(datasheetVars);
    // checkbox null makes the field always appear - making that content non-optional.
    // No field, but with a checkbox makes the content optional but not editable.
    // An array in the field value makes it a dropdown. CurrentLang can be left off.
    // Set currentLang to null to leave off translation dropdown from Edit Content Tab.
    //charLimit is a key you can set inside an option object for input field
    setContentOptions(datasheetOpts);
    //you can set a section as default fullwidth like below width:
    setDimensions({
      ...dimensions,
      hardcodeHeight: false,
      viewHeight: "700",
      width: window.innerWidth - 24
    });
  }, []);
  //   Customize this loader text
  if (!contentOptions) return "...Loading Datasheet";
  return (
    // <DropZone setPhone={setPhone} setTablet={setTablet} setDesktop={setDesktop} pictureTag={true}>
    // </DropZone>
    <div className="u-datasheet">
      <div className="container">
        <div className="one-page one-page--firstpage">
          <header className="section-header-kingston">
            <div className="logo">
              <img
                alt="Kingston Logo"
                className="logo-kingston"
                src="/images/kingston-logo-blkText.svg"
              />
            </div>
          </header>
          <section className="section-product-identity">
            {/* new component */}
            <div className="c-productimage">
              <img src="https://media.kingston.com/kingston/product/ktc-product-ssd-kc3000-1-tn.png" />
            </div>
            <div className="l-row__col">
              <div className="c-heading">DataTraveler Max USB 3.2 Gen 2 Series Flash Drive</div>
              <div className="c-subHeading">
                Available in USB-C<span className="u-txt-registered">&reg;</span> or USB-A
              </div>
            </div>
          </section>

          <section className="section-product-description">
            {/* old text left here to remind us to provide footnote styles */}
            <p>
              Kingston’s DataTraveler<span className="u-txt-registered">&reg;</span> Max series USB
              flash drives leverage the latest USB 3.2 Gen 2 standard to deliver record-breaking
              read/write speeds up to 1,000/900MB/s
              <sup className="u-txt-footnote" data-original="{{Footnote.N37139}}">
                1
              </sup>
              . Designed with portability and convenience in mind, the unique ridged casing protects
              the connector when not in use and is easily moved with a single motion. Available in
              USB Type-C<span className="u-txt-registered">&reg;</span>
              <sup className="u-txt-footnote" data-original="{{Footnote.N59135}}">
                2{" "}
              </sup>
              and Type-A connections to support next-gen and traditional laptops and desktops. The
              DT Max series delivers premium performance and expanded capacities up to 1TB
              <sup className="u-txt-footnote" data-original="{{Footnote.N13848}}">
                3{" "}
              </sup>
              making them the ideal solution for transferring and storing large digital files such
              as HD photos, 4K/8K videos, music and more. Shorter Product discription 10px.
              Characters 430 Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
              tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </section>
          <section className="section-product-features">
            {/* <div className="l-inner"> */}
            {/* We may have hide svg bullet to use different bullet */}
            <p>
              <span>Advanced USB 3.2 Gen 2 Drives</span>
              <span>
                Unique ridged casing with keyring loop Advanced USB 3.2 Gen 2 Drives Advanced USB
                3.2 Gen 2 Drives
              </span>
              <span>Up to 1,000MB/s1 read speeds</span>
              <span>
                Capacities up to 1TB
                <sup className="footnote-sub">3</sup>
              </span>
            </p>
            {/* </div> */}
          </section>

          <div className="one-page">
            <section className="section-specs">
              <div className="section-title">
                <span className="c-tableTitle">Specifications</span>
                <span className="c-subtitle">2.5"</span>
              </div>
              <table className="c-table c-table--striped c-table--2col">
                <tbody>
                  <tr>
                    <td>Form Factor</td>
                    <td>2.5"</td>
                  </tr>
                  <tr>
                    <td>Interface</td>
                    <td>
                      SATA Rev. 3.0 (6Gb/s) - with backwards capability to SATA Rev 2.0 (3Gb/s)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Capacities
                      <sup className="footnote-sub" data-original="{{Footnote.N37130}}">
                        2
                      </sup>
                    </td>
                    <td>256GB, 512GB, 1024GB, 2048GB</td>
                  </tr>
                  <tr>
                    <td>Controller</td>
                    <td>SM2259</td>
                  </tr>
                  <tr>
                    <td>NAND</td>
                    <td>3D TLC</td>
                  </tr>
                  <tr>
                    <td>Encrypted</td>
                    <td>XTS-AES 256-bit encryption</td>
                  </tr>
                  <tr>
                    <td>
                      Sequential Read/Write
                      <sup className="footnote-sub" data-original="{{Footnote.N39214}}">
                        1
                      </sup>
                    </td>
                    <td>
                      256GB — up to 550/500MB/s
                      <br />
                      512GB-2048GB — up to 550/520MB/s
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Maximum 4K Read/Write
                      <sup className="footnote-sub" data-original="{{Footnote.N39214}}">
                        1
                      </sup>
                    </td>
                    <td>up to 90,000/80,000 IOPS</td>
                  </tr>
                  <tr>
                    <td>Power Consumption</td>
                    <td>
                      0.06W Idle / 0.2W Avg / 1.3W (MAX) Read / 3.2W (MAX) Write/0.06W Idle / 0.2W
                      Avg / 1.3W (MAX) Read / 3.2W (MAX) Write/0.06W Idle / 0.2W Avg / 1.3W (MAX)
                      Read / 3.2W (MAX) Write/0.06W Idle / 0.2W Avg / 1.3W (MAX) Read / 3.2W (MAX)
                      Write
                    </td>
                  </tr>
                  <tr>
                    <td>Dimensions</td>
                    <td>100.1mm x 69.85mm x 7mm</td>
                  </tr>
                  <tr>
                    <td>Weight</td>
                    <td>40g</td>
                  </tr>
                  <tr>
                    <td>Operating temperature</td>
                    <td>0°C~70°C</td>
                  </tr>
                  <tr>
                    <td>Storage temperature</td>
                    <td>-40°C~85°C</td>
                  </tr>
                  <tr>
                    <td>Vibration operating</td>
                    <td>2.17G Peak (7-800Hz)</td>
                  </tr>
                  <tr>
                    <td>Vibration non-operating</td>
                    <td>20G Peak (10-2000Hz)</td>
                  </tr>
                  <tr>
                    <td>Life expectancy</td>
                    <td>1 millions hours MTBF</td>
                  </tr>
                  <tr>
                    <td>
                      Warranty/support
                      <sup className="footnote-sub" data-original="{{Footnote.N48982}}">
                        3
                      </sup>
                    </td>
                    <td>Limited 5-year warranty with free technical support</td>
                  </tr>
                  <tr>
                    <td>
                      Total Bytes Written (TBW)
                      <sup className="footnote-sub" data-original="{{Footnote.N52588}}">
                        4
                      </sup>
                    </td>
                    <td>
                      256GB — 150TB
                      <br />
                      512GB — 300TB
                      <br />
                      1024GB — 600TB
                      <br />
                      2048GB — 1200TB
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
          <div className="one-page">
            <section className="section-specs">
              <div className="section-title">
                <span className="c-tableTitle">Specifications</span>
                <span className="c-subtitle">mSATA</span>
              </div>
              <table className="c-table c-table--striped c-table--2col">
                <tbody>
                  <tr>
                    <td>Form Factor</td>
                    <td>mSATA</td>
                  </tr>
                  <tr>
                    <td>Interface</td>
                    <td>
                      SATA Rev. 3.0 (6Gb/s) - with backwards capability to SATA Rev 2.0 (3Gb/s)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Capacities
                      <sup className="footnote-sub" data-original="{{Footnote.N37130}}">
                        2
                      </sup>
                    </td>
                    <td>256GB, 512GB, 1024GB</td>
                  </tr>
                  <tr>
                    <td>Controller</td>
                    <td>SM2259</td>
                  </tr>
                  <tr>
                    <td>NAND</td>
                    <td>3D TLC</td>
                  </tr>
                  <tr>
                    <td>Encrypted</td>
                    <td>XTS-AES 256-bit encryption</td>
                  </tr>
                  <tr>
                    <td>
                      Sequential Read/Write
                      <sup className="footnote-sub" data-original="{{Footnote.N39214}}">
                        1
                      </sup>
                    </td>
                    <td>
                      256GB — up to 550/500MB/s
                      <br />
                      512GB - 1024GB — up to 550/520MB/s
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Maximum 4K Read/Write
                      <sup className="footnote-sub" data-original="{{Footnote.N39214}}">
                        1
                      </sup>
                    </td>
                    <td>up to 90,000/80,000 IOPS</td>
                  </tr>
                  <tr>
                    <td>Power Consumption</td>
                    <td>
                      0.08W Idle / 0.1W Avg / 1.2W (MAX) Read / 2.4W (MAX) Write /0.08W Idle / 0.1W
                      Avg / 1.2W (MAX) Read / 2.4W (MAX) Write/0.08W Idle / 0.1W Avg / 1.2W (MAX)
                      Read / 2.4W (MAX) Write/0.08W Idle / 0.1W Avg / 1.2W (MAX) Read / 2.4W (MAX)
                      Write
                    </td>
                  </tr>
                  <tr>
                    <td>Dimensions</td>
                    <td>50.8mm x2 9.85mm x 4.85mm</td>
                  </tr>
                  <tr>
                    <td>Weight</td>
                    <td>7g</td>
                  </tr>
                  <tr>
                    <td>Operating temperature</td>
                    <td>0°C~70°C</td>
                  </tr>
                  <tr>
                    <td>Storage temperature</td>
                    <td>-40°C~85°C</td>
                  </tr>
                  <tr>
                    <td>Vibration operating</td>
                    <td>2.17G Peak (7-800Hz)</td>
                  </tr>
                  <tr>
                    <td>Vibration non-operating</td>
                    <td>20G Peak (10-2000Hz)</td>
                  </tr>
                  <tr>
                    <td>Life expectancy</td>
                    <td>1 millions hours MTBF</td>
                  </tr>
                  <tr>
                    <td>
                      Warranty/support
                      <sup className="footnote-sub" data-original="{{Footnote.N48982}}">
                        3
                      </sup>
                    </td>
                    <td>Limited 5-year warranty with free technical support</td>
                  </tr>
                  <tr>
                    <td>
                      Total Bytes Written (TBW)
                      <sup className="footnote-sub" data-original="{{Footnote.N52588}}">
                        4
                      </sup>
                    </td>
                    <td>
                      256GB — 150TB
                      <br />
                      512GB — 300TB
                      <br />
                      1024GB — 600TB
                    </td>
                  </tr>
                  <tr>
                    <td>Operating temperature</td>
                    <td>0°C~70°C</td>
                  </tr>
                  <tr>
                    <td>Storage temperature</td>
                    <td>-40°C~85°C</td>
                  </tr>
                  <tr>
                    <td>Vibration operating</td>
                    <td>2.17G Peak (7-800Hz)</td>
                  </tr>
                  <tr>
                    <td>Vibration non-operating</td>
                    <td>20G Peak (10-2000Hz)</td>
                  </tr>
                  <tr>
                    <td>Life expectancy</td>
                    <td>1 millions hours MTBF</td>
                  </tr>
                  <tr>
                    <td>
                      Warranty/support
                      <sup className="footnote-sub" data-original="{{Footnote.N48982}}">
                        3
                      </sup>
                    </td>
                    <td>Limited 5-year warranty with free technical support</td>
                  </tr>
                  <tr>
                    <td>
                      Total Bytes Written (TBW)
                      <sup className="footnote-sub" data-original="{{Footnote.N52588}}">
                        4
                      </sup>
                    </td>
                    <td>
                      256GB — 150TB
                      <br />
                      512GB — 300TB
                      <br />
                      1024GB — 600TB
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section className="section-specs">
              <div className="section-title">
                <span className="c-tableTitle">Part Numbers</span>
                <span className="c-subtitle">Single Module</span>
              </div>
              <table className="c-table c-table--striped c-table--2col">
                <tbody>
                  <tr>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                  </tr>
                  <tr>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                  </tr>
                  <tr>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                  </tr>
                  <tr>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section className="section-specs">
              <div className="section-title">
                <span className="c-tableTitle">Kit of 2</span>
              </div>
              <table className="c-table c-table--striped c-table--2col">
                <tbody>
                  <tr>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                  </tr>
                  <tr>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                  </tr>
                  <tr>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                    <td>KF548C38BBA-8</td>
                  </tr>
                  <tr>
                    <td>KF548C38BBA-8</td> <td>KF548C38BBA-8</td>
                  </tr>
                </tbody>
              </table>
            </section>
            <section className="section-legal">
              <div className="l-inner">
                <ul>
                  <li>
                    <p>
                      This SSD is designed for use in desktop and notebook computer workloads and is
                      not intended for Server environments.
                    </p>
                  </li>
                </ul>
                <ul>
                  <li>
                    <p>
                      1. Based on “out-of-box performance” using a PCIe 4.0 motherboard. Speed may
                      vary due to host hardware, software, and usage.
                    </p>
                  </li>
                  <li>
                    <p>
                      2. Some of the listed capacity on a Flash storage device is used for
                      formatting and other functions and thus is not available for data storage. As
                      such, the actual available capacity for data storage is less than what is
                      listed on the products. For more information, go to Kingston's{" "}
                      <a href="/flashguide" target="_blank">
                        Flash Memory Guide
                      </a>
                      .
                    </p>
                  </li>
                  <li>
                    <p>
                      3. <a href="/ssd/dwpd">Total Bytes Written</a> (TBW) is derived from the JEDEC
                      Client Workload (JESD219A).
                    </p>
                  </li>
                  <li>
                    <p>
                      4. Limited warranty based on 5 years or “Percentage Used” which can be found
                      using the Kingston SSD Manager (
                      <a href="https://www.kingston.com/ssdmanager">kingston.com/ssdmanager</a>).
                      For NVMe SSDs, a new unused product will show a Percentage Used value of 0,
                      whereas a product that reaches its warranty limit will show a Percentage Used
                      value of greater than or equal to one hundred (100). See{" "}
                      <a href="https://www.kingston.com/warranty">kingston.com/warranty</a> for
                      details.
                    </p>
                  </li>
                </ul>
              </div>
            </section>
            <footer className="section-footer page-footer">
              <div className="left-logo">
                <img
                  alt="System Certification SGS Logo"
                  className="logo-sgs"
                  src="https://www.kingstonloda.com/AssetLink/w2526cl68o2ok6xs14ux253ael6y577i.svg"
                />
              </div>
              <div className="copyright">
                <span>
                  ESTE DOCUMENTO ESTÁ SUJETO A CAMBIOS SIN AVISO. ©2023 Kingston Technology
                  Corporation, 17600 Newhope Street, Fountain Valley, CA 92708 USA. Todos los
                  derechos reservados. Todas las marcas comerciales y las marcas registradas son
                  propiedad exclusiva de sus respectivos dueños.
                </span>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  );
};

//path should be component/section name, lowercase. Add dashes if multiple words.
//html name should be component/section name without the S_ and a lowercase s. Don't add until after html is saved.
//categoryType sorts where sections appear in menus. value options are "landing page", "web", "brand", "icons", "internal"

export default {
  path: "datasheet-2025",
  component: S_datsheet2025,
  navtxt: "Datasheet 2025",
  htmlName: "Datsheet2025",
  categoryType: ["mis"]
};
