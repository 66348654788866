export default {
  id: "heading",
  headingmode: {
    label: "Heading Mode",
    checkbox: null,
    field: [
      "Default",
      "Headline"
    ],
    selected: "Default",
  },
  headingtype: {
    label: "Heading Type",
    checkbox: null,
    field: ["H1", "H2", "H3", "H4", "H5", "H6"],
    selected: ["H2"]
  },
  heading1: {
    label: "Heading",
    checkbox: null,
    field: {
      Eng: "Lorem ipsum",
      Span: "Título",
      ChS: "标题",
      Gr: "Überschrift",
      Ru: "Заголовок",
      Fr: "Titre",
      It: "Titolo",
      Pl: "Tytuł",
      Port: "Título",
      Viet: "Cú đội đầu",
      Turk: "Başlık",
      Ukrn: "Заголовок",
      Thai: "หัวเรื่อง",
      Kor: "표제",
      ChT: "標題 ",
      Jp: "見出し",
    },
    charLimit: 50,
  },
  sub: {
    label: "Subheading",
    checkbox: true,
    field: {
      Eng: "Consectetur adipiscing elit",
      Span: "Subtitular",
      ChS: "副标题",
      Gr: "Untertitel",
      Ru: "Подзаголовок",
      Fr: "Sous-titre",
      It: "Sottotitolo",
      Pl: "Podtytuł",
      Port: "Legenda",
      Viet: "Phụ đề",
      Turk: "Alt yazı",
      Ukrn: "Підзаголовок",
      Thai: "คำบรรยาย",
      Kor: "부제",
      ChT: "副標題",
      Jp: "サブタイトル",
    },
    charLimit: 120,
  },
  copy: {
    label: "Copy",
    checkbox: true,
    field: {
      Eng: "Sed ut perspiciatis unde omnis iste natus error",
      Span: "Hacía calor en ese tiempo, para mí,",
      ChS: "那个天气很热，对我来说，我真的不知道……——嗯，",
      Gr: "Tochter; es hieß, sie sei mit Herrn ",
      Ru: "Лорем ипсум долор сит амет, пер цлита поссит",
      Fr: "Il faisait chaud dans ce temps-là, pour moi",
      It: "Faceva caldo con quel tempo, per me, non lo",
      Pl: "Było gorąco w taką pogodę, jak dla mnie",
      Port: "Fazia calor naquele tempo, pra mim",
      Viet: "Thời tiết đó nóng nực, đối với tôi",
      Turk: "O hava sıcaktı, benim için, pek",
      Ukrn: "У таку погоду було спекотно, для",
      Thai: "อากาศช่วงนั้นร้อน สำหรับผม ผมไม่รู้จริงๆ...",
      Kor: "국민경제의 발전을 위한 중요정책의 수립에",
      ChT: "那個天氣很熱，對我來說，我真的不知道……——嗯",
      Jp: "旅ロ京青利セムレ弱改フヨス波府かばぼ意送",
    },
    charLimit: 300,
  },
  currentLang: "Eng",
};
