export default {
  keyfeatures: {
    label: "Enable Key Features [Demo]",
    checkbox: true,
    field: null
  },

  summary: {
    label: "Optional Intro",
    checkbox: true,
    field: {
      Eng: "The Kingston IronKey™ Keypad 200 series are OS-independent hardware-encrypted USB Type-A and USB Type-C®1 drives with an alphanumeric keypad for easy-to-use PIN access.",
      Span: "Canvas Go de Kingston! Además, la microSD es para aventureros que siempre están en movimiento buscando el momento perfecto para capturar. ",
      ChS: "金士顿 Canvas Go!另外，microSD 适合那些总是在旅途中追逐捕捉完美时刻的冒险家。 ",
      Gr: "Kingstons Canvas Go! Darüber hinaus ist microSD für Abenteurer gedacht, die immer auf der Suche nach dem perfekten Moment zum Festhalten sind. ",
      Ru: "Холст Кингстона, вперед! Плюс карта microSD предназначена для искателей приключений, которые всегда в пути в поисках идеального момента, чтобы запечатлеть его.",
      Fr: "Le Canvas Go! De plus, la microSD est destinée aux aventuriers qui sont toujours en déplacement à la recherche du moment parfait à capturer.",
      It: "Canvas Go di Kingston! Inoltre la microSD è per gli avventurieri che sono sempre in movimento alla ricerca del momento perfetto da catturare. ",
      Pl: "Kingston Canvas Go! Plus microSD jest przeznaczony dla poszukiwaczy przygód, którzy są w ciągłym ruchu i szukają idealnego momentu na uchwycenie.",
      Port: "Canvas Go da Kingston! Além disso, o microSD é para aventureiros que estão sempre em movimento em busca do momento perfeito para capturar. ",
      Viet: "Canvas Go của Kingston! Plus microSD dành cho những nhà thám hiểm luôn di chuyển theo đuổi khoảnh khắc hoàn hảo để ghi lại. ",
      Turk: "Kingston's Canvas Go! Plus microSD, her zaman hareket halindeyken yakalamak için mükemmel anı kovalayan maceracılar içindir. ",
      Ukrn: "Kingston Canvas Go! Плюс microSD для любителів пригод, які завжди в дорозі в пошуках ідеального моменту, щоб зафіксувати. ",
      Thai: "Canvas Go ของ Kingston! Plus microSD เหมาะสำหรับนักผจญภัยที่เดินทางตลอดเวลาเพื่อไล่ตามช่วงเวลาที่สมบูรณ์แบบในการถ่ายภาพ ด้วยความเร็วการถ่ายโอนที่เหนือกว่าถึง ",
      Kor: "킹스턴의 Canvas Go! Plus microSD는 캡처할 완벽한 순간을 쫓아 항상 이동하는 모험가를 위한 것입니다. ",
      ChT: "金士頓 Canvas Go!另外，microSD 適合那些總是在旅途中追逐捕捉完美時刻的冒險家。",
      Jp: "キングストンのキャンバスゴー!さらに、microSD は、完璧な瞬間を捉えるために常に外出している冒険家向けです。"
    }
  },

  descAccordion: {
    label: "Description Accordion? ",
    checkbox: true,
    field: null
  },

  longDescAccordion: {
    label: "Description Title [Fixed Value. Test for translations only]",
    checkbox: null,
    field: {
      Eng: "Product Description",
      Span: "Descripción",
      ChS: "描述",
      Gr: "Beschreibung",
      Ru: "Opisaniye",
      Fr: "Description",
      It: "Descrizione",
      Pl: "Opis",
      Port: "Descrição",
      Viet: "Sự miêu tả",
      Turk: "Tanım",
      Ukrn: "опис",
      Thai: "คำอธิบาย",
      Kor: "설명",
      ChT: "描述",
      Jp: "説明"
    }
  },

  enablestickybar: {
    label: "Enable Sticky Bar [Demo]",
    checkbox: false,
    field: null
  },
  stickyimg: {
    label: "Sticky Bar Product Image",
    checkbox: null,
    field: ["Image 1", "Image 2"],
    selected: "Image 1"
  },
  numspecs: {
    label: "Number of Specifications:",
    checkbox: null,
    field: [0, 1, 2, 3, 4, 5, 6],
    selected: 1
  },

  specifications: [
    {
      label: "Specifications Title [Fixed Value. Test for translations only]",
      checkbox: null,
      field: {
        Eng: "Specifications",
        Span: "Presupuesto",
        ChS: "规格",
        Gr: "Spezifikationen",
        Ru: "Технические характеристики",
        Fr: "Caractéristiques",
        It: "Specifiche",
        Pl: "Dane techniczne",
        Port: "Especificações",
        Viet: "Thông số kỹ thuật",
        Turk: "Özellikler",
        Ukrn: "Технічні характеристики",
        Thai: "ข้อมูลจำเพาะ",
        Kor: "명세서",
        ChT: "規格",
        Jp: "仕様"
      },
      block: 1
    },
    {
      label: "Tab 1",
      checkbox: null,
      field: {
        Eng: "Spec 1",
        Span: "Presupuesto",
        ChS: "规格",
        Gr: "Spezifikationen",
        Ru: "Технические характеристики",
        Fr: "Caractéristiques",
        It: "Specifiche",
        Pl: "Dane techniczne",
        Port: "Especificações",
        Viet: "Thông số kỹ thuật",
        Turk: "Özellikler",
        Ukrn: "Технічні характеристики",
        Thai: "ข้อมูลจำเพาะ",
        Kor: "명세서",
        ChT: "規格",
        Jp: "仕様"
      },
      block: 1
    },
    {
      label: "Tab 2",
      checkbox: null,
      field: {
        Eng: "Spec 2",
        Span: "Presupuesto",
        ChS: "规格",
        Gr: "Spezifikationen",
        Ru: "Технические характеристики",
        Fr: "Caractéristiques",
        It: "Specifiche",
        Pl: "Dane techniczne",
        Port: "Especificações",
        Viet: "Thông số kỹ thuật",
        Turk: "Özellikler",
        Ukrn: "Технічні характеристики",
        Thai: "ข้อมูลจำเพาะ",
        Kor: "명세서",
        ChT: "規格",
        Jp: "仕様"
      },
      block: 1
    },
    {
      label: "Tab 3",
      checkbox: null,
      field: {
        Eng: "Spec 3",
        Span: "Presupuesto",
        ChS: "规格",
        Gr: "Spezifikationen",
        Ru: "Технические характеристики",
        Fr: "Caractéristiques",
        It: "Specifiche",
        Pl: "Dane techniczne",
        Port: "Especificações",
        Viet: "Thông số kỹ thuật",
        Turk: "Özellikler",
        Ukrn: "Технічні характеристики",
        Thai: "ข้อมูลจำเพาะ",
        Kor: "명세서",
        ChT: "規格",
        Jp: "仕様"
      },
      block: 1
    },
    {
      label: "Tab 4",
      checkbox: null,
      field: {
        Eng: "Spec 4",
        Span: "Presupuesto",
        ChS: "规格",
        Gr: "Spezifikationen",
        Ru: "Технические характеристики",
        Fr: "Caractéristiques",
        It: "Specifiche",
        Pl: "Dane techniczne",
        Port: "Especificações",
        Viet: "Thông số kỹ thuật",
        Turk: "Özellikler",
        Ukrn: "Технічні характеристики",
        Thai: "ข้อมูลจำเพาะ",
        Kor: "명세서",
        ChT: "規格",
        Jp: "仕様"
      },
      block: 1
    },
    {
      label: "Tab 5",
      checkbox: null,
      field: {
        Eng: "Spec 5",
        Span: "Presupuesto",
        ChS: "规格",
        Gr: "Spezifikationen",
        Ru: "Технические характеристики",
        Fr: "Caractéristiques",
        It: "Specifiche",
        Pl: "Dane techniczne",
        Port: "Especificações",
        Viet: "Thông số kỹ thuật",
        Turk: "Özellikler",
        Ukrn: "Технічні характеристики",
        Thai: "ข้อมูลจำเพาะ",
        Kor: "명세서",
        ChT: "規格",
        Jp: "仕様"
      },
      block: 1
    },
    {
      label: "Tab 6",
      checkbox: null,
      field: {
        Eng: "Spec 6",
        Span: "Presupuesto",
        ChS: "规格",
        Gr: "Spezifikationen",
        Ru: "Технические характеристики",
        Fr: "Caractéristiques",
        It: "Specifiche",
        Pl: "Dane techniczne",
        Port: "Especificações",
        Viet: "Thông số kỹ thuật",
        Turk: "Özellikler",
        Ukrn: "Технічні характеристики",
        Thai: "ข้อมูลจำเพาะ",
        Kor: "명세서",
        ChT: "規格",
        Jp: "仕様"
      },
      block: 1
    }
  ],

  numfeatures: {
    label: "Number of Features:",
    checkbox: null,
    field: [0, 1, 2, 3, 4, 5, 6],
    selected: 4
  },

  features: [
    {
      label: "Features Title [Fixed Value. Test for translations only]",
      checkbox: null,
      field: {
        Eng: "Features",
        Span: "Características",
        ChS: "特征",
        Gr: "Merkmale",
        Ru: "Функции",
        Fr: "Caractéristiques",
        It: "Caratteristiche",
        Pl: "Cechy",
        Port: "Características",
        Viet: "Đặc trưng",
        Turk: "Özellikler",
        Ukrn: "Особливості",
        Thai: "คุณสมบัติ",
        Kor: "특징",
        ChT: "特徵",
        Jp: "特徴"
      },
      block: 2
    },
    // {
    //   label: "Icon Features Icon",
    //   checkbox: null,
    //   field: "#iphone-ipad",
    //   block: 2,
    // },
    {
      label: "Icon Features Copy",
      checkbox: null,
      field: {
        Eng: "Lorem ipsum.",
        Span: "Características",
        ChS: "特征",
        Gr: "Merkmale",
        Ru: "Функции",
        Fr: "Caractéristiques",
        It: "Caratteristiche",
        Pl: "Cechy",
        Port: "Características",
        Viet: "Đặc trưng",
        Turk: "Özellikler",
        Ukrn: "Особливості",
        Thai: "คุณสมบัติ",
        Kor: "특징",
        ChT: "特徵",
        Jp: "特徴"
      },
      block: 2
    }
  ],

  numresources: {
    label: "Number of Resources:",
    checkbox: null,
    field: [0, 1, 2, 3, 4, 5, 6],
    selected: 3
  },

  resources: [
    {
      label: "Resources Title",
      checkbox: null,
      field: {
        Eng: "Resources",
        Span: "Recursos",
        ChS: "资源",
        Gr: "Ressourcen",
        Ru: "Ресурсы",
        Fr: "Ressources",
        It: "Risorse",
        Pl: "Zasoby",
        Port: "Recursos",
        Viet: "Tài nguyên",
        Turk: "Kaynaklar",
        Ukrn: "Ресурси",
        Thai: "ทรัพยากร",
        Kor: "자원",
        ChT: "資源",
        Jp: "リソース"
      },
      block: 3
    },
    {
      label: "Link 1",
      checkbox: null,
      field: {
        Eng: "Part Number Decoder",
        Span: "Decodificador de número de pieza",
        ChS: "零件编号解码器",
        Gr: "Teilenummer-Decoder",
        Ru: "Декодер номера детали",
        Fr: "Décodeur de numéro de pièce",
        It: "Decodificatore del numero di parte",
        Pl: "Dekoder numeru części",
        Port: "Decodificador de número de peça",
        Viet: "Bộ giải mã số phần",
        Turk: "Parça Numarası Kod Çözücü",
        Ukrn: "Декодер номерів деталей",
        Thai: "ตัวถอดรหัสหมายเลขชิ้นส่วน",
        Kor: "부품 번호 디코더",
        ChT: "零件編號解碼器",
        Jp: "部品番号デコーダ"
      },
      block: 3
    },
    {
      label: "Link 2",
      checkbox: null,
      field: {
        Eng: "How to install",
        Span: "Cómo instalar",
        ChS: "如何安装",
        Gr: "Anleitung zur Installation",
        Ru: "Как установить",
        Fr: "Comment installer",
        It: "Come installare",
        Pl: "Jak zainstalować",
        Port: "Como instalar",
        Viet: "Cách cài đặt",
        Turk: "Nasıl kurulur",
        Ukrn: "Як встановити",
        Thai: "วิธีการติดตั้ง",
        Kor: "설치 방법",
        ChT: "如何安裝",
        Jp: "インストール方法"
      },
      block: 3
    },
    {
      label: "Link 3",
      checkbox: null,
      field: {
        Eng: "Overclocking",
        Span: "Overclocking",
        ChS: "超频",
        Gr: "Übertakten",
        Ru: "Разгон",
        Fr: "Overclocking",
        It: "Overclocking",
        Pl: "Podkręcanie",
        Port: "Overclock",
        Viet: "Ép xung",
        Turk: "Hız aşırtma",
        Ukrn: "Розгін",
        Thai: "การโอเวอร์คล็อก",
        Kor: "오버클러킹",
        ChT: "超頻",
        Jp: "オーバークロック"
      },
      block: 3
    }
  ],

  numreviews: {
    label: "Number of Reviews:",
    checkbox: null,
    field: [1, 2, 3, 4, 5, 6],
    selected: 2
  },

  reviews: [
    {
      label: "Reviews Title [Fixed Value. Test for translations only]",
      checkbox: null,
      field: {
        Eng: "Awards and reviews",
        Span: "Premios y reseñas",
        ChS: "奖项和评论",
        Gr: "Auszeichnungen und Rezensionen",
        Ru: "Награды и отзывы",
        Fr: "Prix ​​​​et critiques",
        It: "Premi e recensioni",
        Pl: "Nagrody i recenzje",
        Port: "Prêmios e avaliações",
        Viet: "Giải thưởng và đánh giá",
        Turk: "Ödüller ve incelemeler",
        Ukrn: "Нагороди та відгуки",
        Thai: "รางวัลและบทวิจารณ์",
        Kor: "수상 및 리뷰",
        ChT: "獎項和評論",
        Jp: "受賞歴とレビュー"
      },
      block: 4
    },
    {
      label: "Review 1 Copy",
      checkbox: null,
      field: {
        Eng: "“Kingston’s FURY Renegade SSD 2000G roars onto the scene, leaving dust in its wake. This 2TB M.2 NVMe monster unleashes a ferocious blend of cutting-edge speed, rock-solid reliability, and features tailor-made for demanding gamers and professionals.”",
        Span: "“El FURY Renegade SSD 2000G de Kingston entra en escena con un rugido, dejando polvo a su paso. Este monstruo NVMe M.2 de 2TB desata una combinación feroz de velocidad de vanguardia, confiabilidad sólida y características hechas a medida para jugadores y profesionales exigentes”.",
        ChS: "“金士顿 FURY Renegade SSD 2000G 呼啸而至，留下灰尘。这款 2TB M.2 NVMe 怪兽将尖端速度、坚如磐石的可靠性融为一体，并具有专为要求苛刻的游戏玩家和专业人士量身定制的功能。”",
        Gr: "„Kingstons FURY Renegade SSD 2000G rast auf die Bildfläche und hinterlässt Staub im Kielwasser. Dieses 2-TB-M.2-NVMe-Monster bietet eine beeindruckende Mischung aus modernster Geschwindigkeit, absoluter Zuverlässigkeit und maßgeschneiderten Funktionen für anspruchsvolle Gamer und Profis.“",
        Ru: "«Твердотельный накопитель FURY Renegade 2000G компании Kingston с ревом вырывается на сцену, оставляя за собой пыль. Этот монстр M.2 NVMe емкостью 2 ТБ представляет собой невероятное сочетание высочайшей скорости, высочайшей надежности и функций, специально разработанных для требовательных геймеров и профессионалов».",
        Fr: "« Le FURY Renegade SSD 2000G de Kingston arrive sur scène, laissant de la poussière dans son sillage. Ce monstre M.2 NVMe de 2 To libère un mélange féroce de vitesse de pointe, de fiabilité à toute épreuve et de fonctionnalités conçues sur mesure pour les joueurs et les professionnels exigeants.",
        It: "“L’SSD 2000G FURY Renegade di Kingston irrompe sulla scena, lasciando dietro di sé polvere. Questo mostro M.2 NVMe da 2 TB scatena una feroce miscela di velocità all'avanguardia, affidabilità solida come la roccia e funzionalità su misura per giocatori e professionisti esigenti.",
        Pl: "„Dysk SSD FURY Renegade 2000G firmy Kingston wjeżdża z rykiem na miejsce zdarzenia, pozostawiając po sobie kurz. Ten potwór M.2 NVMe o pojemności 2 TB zapewnia niesamowitą mieszankę najnowocześniejszej szybkości, solidnej niezawodności i funkcji dostosowanych do potrzeb wymagających graczy i profesjonalistów”.",
        Port: "“O FURY Renegade SSD 2000G da Kingston entra em cena, deixando poeira em seu rastro. Este monstro M.2 NVMe de 2 TB libera uma mistura feroz de velocidade de ponta, confiabilidade sólida e recursos feitos sob medida para jogadores e profissionais exigentes.”",
        Viet: "“SSD FURY Renegade 2000G của Kingston gầm rú tại hiện trường, để lại bụi bặm sau lưng. Con quái vật M.2 NVMe 2TB này giải phóng sự kết hợp mạnh mẽ giữa tốc độ tiên tiến, độ tin cậy vững chắc và các tính năng được thiết kế riêng cho các game thủ và chuyên gia khó tính.”",
        Turk: "“Kingston'ın FURY Renegade SSD 2000G'si, arkasında toz bırakarak sahneye çıkıyor. Bu 2 TB M.2 NVMe canavarı, son teknoloji hız, son derece sağlam güvenilirlik ve zorlu oyuncular ve profesyoneller için özel olarak tasarlanmış özelliklerin vahşi bir karışımını ortaya çıkarıyor.”",
        Ukrn: "«Kingston FURY Renegade SSD 2000G виривається на сцену, залишаючи пил за собою. Цей монстр M.2 NVMe об’ємом 2 ТБ реалізує неймовірне поєднання передової швидкості, неймовірної надійності та функцій, створених спеціально для вимогливих геймерів і професіоналів».",
        Thai: "“FURY Renegade SSD 2000G ของ Kingston ส่งเสียงคำรามในที่เกิดเหตุ ทิ้งฝุ่นเอาไว้ สัตว์ประหลาด M.2 NVMe ขนาด 2TB นี้ปลดปล่อยการผสมผสานที่ดุเดือดของความเร็วที่ล้ำหน้า ความน่าเชื่อถือที่แข็งแกร่ง และคุณสมบัติที่ออกแบบเป็นพิเศษสำหรับนักเล่นเกมและมืออาชีพที่ต้องการ”",
        Kor: "“Kingston의 FURY Renegade SSD 2000G가 먼지를 남기며 현장으로 굉음을 내었습니다. 이 2TB M.2 NVMe 괴물은 최첨단 속도, 견고한 안정성, 까다로운 게이머와 전문가를 위해 맞춤 제작된 기능을 혼합하여 제공합니다.”",
        ChT: "「金士頓 FURY Renegade SSD 2000G 呼嘯而至，留下灰塵。這款 2TB M.2 NVMe 怪獸將尖端速度、堅如磐石的可靠性融為一體，並具有專為要求苛刻的遊戲玩家和專業人士量身定制的功能。",
        Jp: "「キングストンの FURY Renegade SSD 2000G が轟音を立てて現場に登場し、その後に塵を残しました。この 2TB M.2 NVMe モンスターは、最先端のスピードと堅固な信頼性の猛烈な融合を解き放ち、要求の厳しいゲーマーやプロフェッショナル向けにカスタマイズされた機能を備えています。」"
      },
      block: 4
    },
    {
      label: "Review 1 CTA",
      checkbox: null,
      field: {
        Eng: "Lorem Ipsum",
        Span: "Leer más",
        ChS: "阅读更多",
        Gr: "Mehr lesen",
        Ru: "Читать далее",
        Fr: "En savoir plus",
        It: "Per saperne di più",
        Pl: "Przeczytaj więcej",
        Port: "Leia mais",
        Viet: "Đọc thêm",
        Turk: "Devamını oku",
        Ukrn: "Читати далі",
        Thai: "อ่านเพิ่มเติม",
        Kor: "더 읽어보세요",
        ChT: "閱讀更多",
        Jp: "続きを読む"
      },
      block: 4
    }
  ],

  productname: {
    label: "Product Name",
    checkbox: null,
    field: "Kingston IronKey Vault Privacy 50 Series"
  },

  priceoptions: {
    label: "Price Options",
    checkbox: null,
    field: ["Default", "Discount", "Range", "None"],
    selected: "Default"
  },

  productdiscount: {
    label: "Discount",
    checkbox: null,
    field: "£555.55"
  },

  productprice: {
    label: "Price",
    checkbox: null,
    field: "£342.24"
  },

  currentLang: "Eng"
};
